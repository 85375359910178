import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import 'typeface-poppins'; // Importa la fuente Poppins

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    // Configura la fuente Poppins para la aplicación
    fonts: {
      body: 'Poppins, sans-serif',
      heading: 'Poppins, sans-serif',
    },
  },
});
