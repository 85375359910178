<template>
  <div>
    <v-app>
      <Header />
      <Intro />
      <v-main style="padding: 0px 0px 0px; background-color: black;">
        <tags1 />
        <howItWorks />
        <Assistant />
        <tags2 />
        <Support />
        <div
          style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000,  #000000,#3b3b3b, #777777, #b9b9b9, #ffffff);">
          <platformPreview />
        </div>

      </v-main>
      <Footer />

    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'
import Intro from '@/components/components_landing_home/Intro.vue'
import tags1 from '@/components/components_landing_home/tags1.vue'
import howItWorks from '@/components/components_landing_home/howItWorks.vue'
import Assistant from '@/components/components_landing_home/customAsisstant.vue'
import tags2 from '@/components/components_landing_home/tags2.vue'
import Support from '@/components/components_landing_home/supportAssistant.vue'
import platformPreview from '@/components/components_landing_home/platformPreview.vue'






export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    Intro,
    tags1,
    howItWorks,
    Assistant,
    tags2,
    Support,
    platformPreview
  },
}
</script>



