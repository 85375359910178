<template>
    <div>
        <v-container style="margin-top: 130px;  text-align: center;">
            <center>
                <h2 class="intro-text-head hidden-sm-and-down">Unlock Your <u>Business Potential</u> with Our Plans</h2>
                <h2 class="intro-text-head-mb hidden-md-and-up">Unlock Your <u>Business Potential</u> with Our Plans</h2>
                <p class="intro-text-head-desc mt-2 hidden-sm-and-down">Whether you're a startup or a growing business,
                    we've got you covered.
                    Choose from our Starter, Plus, Premium, or Ultimate plans, designed to meet your needs and exceed your
                    expectations. Experience the power of <u>ChatGPT</u> today.</p>
                <p class="intro-text-head-desc-mb mt-8 hidden-md-and-up ml-2 mr-2" style="text-align: justify;">Whether
                    you're a startup or a growing business, we've got you covered.
                    Choose from our plans, designed to exceed your expectations.</p>
            </center>
        </v-container>
        <v-container fluid>
            <center>
                <v-row>
                    <v-col v-for="(plan, index) in plans" :key="index" class="mx-auto">
                        <v-card style="border-radius: 15px; height: 320px; width: 270px;">
                            <v-card-title class="ml-2">{{ plan.title }}<v-btn v-if="plan.title == 'Plus'" class="ml-5" depressed rounded outlined x-small>Popular</v-btn></v-card-title>
                            <h1 class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">
                                {{ plan.price }}<span style="font-size: small; font-weight: lighter;">/month</span>
                            </h1>
                            <v-list-item three-line class="mt-4">
                                <v-list-item-content class="ml-2" style="text-align: left;">
                                    <v-list-item-title v-for="(feature, fIndex) in plan.features" :key="fIndex">
                                        <v-icon left size="15" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                                        <span style="font-size: small;">{{ feature }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-actions class="pt-10">
                                <v-btn @click="subscribePlan(index)" rounded color="black" block class="white--text">Subscribe</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </center>
        </v-container>
        <center>
            <v-card style="width: fit-content; border-radius: 10px; margin-bottom: 200px;" class="mt-9 glass-card">
                <v-row>
                    <v-col style="max-width:20%;">
                        <v-img contain style="width: 25px;" class="ml-3"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png"></v-img>
                    </v-col>
                    <v-col style="text-align: left;">
                        <span style="font-size: small;">Powered by ChatGPT</span>
                    </v-col>
                </v-row>
            </v-card>
        </center>
    </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
    name: 'HomeView',
    components: {},
    data() {
        return {
            plans: [
                {
                    title: 'Free',
                    price: '$0',
                    features: [
                        '1 ChatGPT Assistant',
                        '20 credits/month'
                    ]
                },
                {
                    title: 'Starter',
                    price: '$17',
                    features: [
                        '4 ChatGPT Assistants',
                        '2,000 credits/month'
                    ]
                },
                {
                    title: 'Plus',
                    price: '$47',
                    features: [
                        '10 ChatGPT Assistants',
                        '5,000 credits/month'
                    ]
                },
                {
                    title: 'Premium',
                    price: '$97',
                    features: [
                        '20 ChatGPT Assistants',
                        '10,000 credits/month'
                    ]
                },
                {
                    title: 'Ultimate',
                    price: '$397',
                    features: [
                        '40 ChatGPT Assistants',
                        '40,000 credits/month'
                    ]
                }
            ]
        };
    },
    methods: {
        subscribePlan(plan) {
            console.log("El usuario seleccionó el plan " + plan);
            this.$router.push("/Register")
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.431);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>

