<template>
    <div>
        <v-app>
            <Header />
            <v-main
                style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000,  #000000, #000000,  #636363, #ffffff);">
                <v-container style="margin-top: 130px; margin-bottom: 200px; text-align: center;">
                    <center>
                        <h2 class="intro-text-head hidden-sm-and-down">Terms and Conditions</h2>
                        <h2 class="intro-text-head-mb hidden-md-and-up">Terms and Conditions</h2>

                        <p class="intro-text-head-desc mt-2 hidden-sm-and-down">By registering on our platform, you
                            acknowledge and agree to adhere to the terms and conditions set forth.</p>
                        <p class="intro-text-head-desc-mb mt-8 hidden-md-and-up ml-2 mr-2" style="text-align: justify;">By
                            registering on our platform, you acknowledge and agree to adhere to the terms and conditions set
                            forth.</p>

                        <v-card class="glass-card mt-12" style="border-radius: 20px; ">
                            <br>
                            <v-row>
                                <v-col class="ml-8 mr-8">
                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Collected
                                        Data</h2>
                                    <p style="color: white; text-align: justify;">Your email address may be utilized for
                                        purposes such as communicating with you regarding your account, providing updates
                                        about our products, and sharing marketing-related information. You retain the option
                                        to unsubscribe from these emails whenever you desire.</p>

                                    <p style="color: white; text-align: justify;">The additional data collected, such as
                                        Country, username, and industry, serve the purpose of gaining demographic insights
                                        about our customers and enabling us to make substantial improvements to our product.
                                        This information allows us to better understand our user base and tailor our
                                        offerings to meet their specific needs. Rest assured that we handle our users data
                                        with utmost care and in compliance with applicable privacy regulations.</p>

                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Billing &
                                        Refunds</h2>
                                    <p style="color: white; text-align: justify;">
                                        We require payment for accessing our product through monthly subscription plans.

                                        Given the unique characteristics of our product, we regret to inform you that we do
                                        not currently provide any form of refunds, whether partial or complete.

                                        You have the freedom to effortlessly terminate your subscription whenever you
                                        desire. Once your subscription is canceled, no further charges will be incurred.

                                        Please note that we reserve the right to modify our pricing, pricing policies,
                                        features, and access restrictions at any given time.
                                    </p>




                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Conditions
                                    </h2>
                                    <p style="color: white; text-align: justify;">
                                        We retain the prerogative to modify or revise the policy at any given time. <br>By continuing to use our platform, you hereby consent and agree to abide by the
                                        aforementioned terms and conditions.
                                        <br>
                                        <br>
                                        Chatpro is not responsible for the quality or responses of the chatbot/assistant as it depends entirely on the quality of the data loaded for training. The gpt-3.5-turbo model can hallucinate if it does not have enough training information.
                                    </p>



                                </v-col>
                            </v-row>

                        </v-card>
                    </center>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}</style>