<template>
    <div>
        <v-container>
            <v-card class="mx-auto mt-16 glass-card hidden-sm-and-down" elevation="3" outlined>
                <v-row class="mb-3">
                    <v-col style="max-width: 450px;">
                        <v-img contain class="mt-8" src="https://d153chakadu90d.cloudfront.net/web/first_chat_example_2.png"
                            height="90%" alt="Custom ChatGPT chat for business example 1"></v-img>
                    </v-col>
                    <v-col>
                        <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">Custom assistant for each
                            client.</h2>
                        <p style="color: white;" class="mt-10 p-text-pc">Create Assistants that can answer any question about your products and your company in many languages.</p>

                        <p style="color: white;" class="mt-10 p-text-pc">Show your clients what they are really looking for
                            with
                            <b>accurate recommendations</b>.
                        </p>
                        
                            <v-btn @click="goToRegister()" rounded class="mt-9">
                                View more <v-icon right>mdi-chevron-right</v-icon>
                            </v-btn>
                    </v-col>
                </v-row>
            </v-card>

            <v-card class="mx-auto mx-1 mt-16 glass-card  hidden-md-and-up" elevation="3" outlined>
                <v-row class="mt-3">
                    <v-img contain src="https://d153chakadu90d.cloudfront.net/web/first_chat_example_2.png"
                        width="10px" alt="Custom ChatGPT Assistant chat for business"></v-img>
                </v-row>
                <v-row>
                    <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">Custom assistant for each
                        client.</h2>
                    <p style="color: white;" class="ml-5 mt-3">Create Assistants that can answer any question about your products and your company in many languages.</p>

                    <p style="color: white;" class="ml-5 mr-2">Show your clients what they are really looking for with
                        <b>accurate recommendations</b>.
                    </p>
                    <v-btn  @click="goToRegister()" rounded class="mt-5 mb-10 ml-5">
                        View more <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>

                </v-row>
            </v-card>

        </v-container>
    </div>
</template>
  
<script>

// @ is an alias to /src  

export default {
    name: 'InfoEmpresas',
    components: {
    },

    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro landing
        },
    }
}
</script>
  
<style scoped>
.glass-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 1100px;
    border-image: linear-gradient(45deg, rgb(250, 250, 250), black) 1;

}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}

.v-btn {
    text-transform: none;
}

.v-card {
    border-radius: 12px;
    padding: 10px;
}

.p-text-pc {
    font-size: 1.2rem;
}
</style>
  
  