<template>
    <div>
        <v-parallax dark src="https://d153chakadu90d.cloudfront.net/web/parallax_test_2.png" class="hidden-sm-and-down"
            style="background-color: #000000;" height="100%" width="100%">
            <v-container style="margin-top: 220px; margin-bottom: 220px; text-align: center;">
                <center>
                    <h2 class="intro-text-head" style="width: 55%;">Create <u>ChatGPT</u> Assistants for your business in a
                        few clicks.</h2>
                    <p class="intro-text-head-desc mt-2" style="width: 80%;">Upload a PDF or write your knowledge
                        base and get a ChatGPT Assistant that can answer any question for you and your clients and also add
                        a chat widget to your site.</p>
                    
                        <v-btn @click="goToRegister()" rounded color="white" class="mt-8">
                            <span style="font-weight: 500;">Start now</span> <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                </center>
            </v-container>
        </v-parallax>

        <v-parallax dark src="https://d153chakadu90d.cloudfront.net/web/parallax_mb.png" class="hidden-md-and-up"
            style="background-color: #000000;" height="100%" width="100%">
            <v-container style="margin-top: 210px; margin-bottom: 210px;">
                <center>
                    <h2 class="intro-text-head-mb">Create <u>ChatGPT</u></h2>
                    <h2 class="intro-text-head-mb">Assistants for your business in a few clicks.</h2>
                    <p class="intro-text-head-desc-mb mt-4">Upload a PDF or write your knowledge
                        base and get a ChatGPT Assistant that can answer any question for you and your clients and also add
                        a chat widget to your site.</p>
                    
                        <v-btn @click="goToRegister()" rounded color="white" class="mt-8">
                            <span style="font-weight: 500;">Start now</span> <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                </center>
            </v-container>
        </v-parallax>
    </div>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'HomeView',
    components: {
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro register
        },
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 45px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>

