<template>
    <div>
        <v-container>
            <v-card class="mx-auto mt-16 glass-card hidden-sm-and-down" elevation="3" outlined>
                <v-row class="mb-8">
                    <v-col class="ml-10">
                        <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">For Support and more.
                        </h2>
                        <p style="color: white;" class="mt-10 p-text-pc">Personalized support assistants for your customers
                            and employees!</p>

                        <p style="color: white;" class="mt-10 p-text-pc">The possibilities are endless. From an HR assistant
                            to technical support for your projects, our technology adapts to your needs.</p>
                        <v-btn  @click="goToRegister()" rounded class="mt-9">
                            Create <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col style="max-width: 450px;">
                        <v-img contain src="https://d153chakadu90d.cloudfront.net/web/second_chat_example.png"
                            height="110%" alt="Custom ChatGPT chat for business example 2"></v-img>
                    </v-col>
                </v-row>
            </v-card>

            <v-card class="mx-auto mx-1 mt-16 glass-card  hidden-md-and-up" elevation="3" outlined>
                <v-row class="mt-3">
                    <v-img contain src="https://d153chakadu90d.cloudfront.net/web/second_chat_example.png"
                        width="10px" alt="Custom ChatGPT chat for business example 2"></v-img>
                </v-row>
                <v-row>
                    <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">For Support and more.
                    </h2>
                    <p style="color: white;" class="ml-5 mt-3">Personalized support assistants for your customers and
                        employees!</p>

                    <p style="color: white;" class="ml-5 mr-2">The possibilities are endless. From an HR assistant to
                        technical support for your projects, our technology adapts to your needs.</p>
                    
                        <v-btn  @click="goToRegister()" rounded class="mt-5 mb-10 ml-5">
                            Create <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                </v-row>
            </v-card>

        </v-container>
    </div>
</template>
  
<script>

// @ is an alias to /src  

export default {
    name: 'InfoEmpresas',
    components: {
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro landing
        },
    }
}
</script>
  
<style scoped>
.glass-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 1100px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;

}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;
    border-radius: 20px;
}

.v-btn {
    text-transform: none;
}

.v-card {
    border-radius: 12px;
    padding: 10px;
}

.p-text-pc {
    font-size: 1.2rem;
}</style>
  
  