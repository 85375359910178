import { render, staticRenderFns } from "./howItWorks.vue?vue&type=template&id=a524de98&scoped=true&"
import script from "./howItWorks.vue?vue&type=script&lang=js&"
export * from "./howItWorks.vue?vue&type=script&lang=js&"
import style0 from "./howItWorks.vue?vue&type=style&index=0&id=a524de98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a524de98",
  null
  
)

export default component.exports