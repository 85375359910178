import axios from "axios";
import authFirebase from "../../authFirebase";
// import ENDPOINT_API_USERS from "@/logic/globalEndpoints";
const {ENDPOINT_API_USERS} = require("@/logic/globalEndpoints")


export default {


    //++++++++++++++++ Metodo POST para REGISTRAR UN NUEVO USUARIO A CHATPRO +++++++++++++++++++++++++
    async signup(id_user, name_user, email_user, industry_user, country_user) {

        const new_register_user_model = {
            id_user: id_user,
            name_user: name_user,
            email_user: email_user,
            industry_user: industry_user,
            country_user: country_user
        };

        const resp = await axios.post(ENDPOINT_API_USERS + "/api/b2c/data-user", new_register_user_model)
        return resp;
    },

    //++++++++++++++++ Metodo GET para TRAER Info del USUARIO +++++++++++++++++++++++++
    async get_user_data(id_user) {

        const params = {
            id_user: id_user,
        };

        const headers = {
            Authorization: 'Bearer ' + await authFirebase.createTokenJWT()
        };

        const resp = await axios.get(ENDPOINT_API_USERS + "/api/b2c/data-user", { params, headers })
        return resp;
    },

    //++++++++++++++++ Metodo UPDATE para ACTUALIZAR EL COLOR POR "id_Company" +++++++++++++++++++++++++
    async update_user_data(id_user, name_user, industry_user, country_user) {
        const updateSchema = {
            id_user: id_user,
            name_user: name_user,
            industry_user: industry_user,
            country_user: country_user
        };
        // const headers = {
        //     headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        // };
        const resp = await axios.put(ENDPOINT_API_USERS + "/api/b2c/data-user", updateSchema)
        return resp;
    },


}