<template>
    <div>
        <v-app>
            <Header />
            <v-main style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000, #000000, #000000,#3b3b3b, #777777, #b9b9b9, #ffffff);">
                <pricing/>
            </v-main>
            <Footer />
        </v-app>
    </div>
</template>
  
<script>
// @ is an alias to /src
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'
import pricing from '@/components/components_landing_home/pricingSection.vue'


export default {
    name: 'HomeView',
    components: {
        Header,
        Footer,
        pricing
    },
}
</script>
  
  
  
  