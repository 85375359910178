<template>
    <v-container style="text-align: center;" class="mt-6 ">
        <center>
            <h2 class="hidden-sm-and-down title-style mt-7">How it works.</h2>
            <h2 class="hidden-md-and-up title-style-mb mt-3">How it works.</h2>
            <p class="mt-6" style="color: white; font-size: larger;">Create <b>ChatGPT</b> Assistants trained on your own content.</p>
            <v-row class="mt-10">
                <v-col>
                    <v-card class="glass-card" style="width: 370px; border-radius: 15px;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 110px; text-align: right" class="mr-3">

                                <v-img src="https://d153chakadu90d.cloudfront.net/web/hiw_test.png" class="ml-5 mt-3"
                                    width="120%" alt="ChatGPT ChatPro How it Works"></v-img>
                            </v-col>

                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">1. Training</p>
                                <p style="color:white;">Upload a PDF or write a knowledge base.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="glass-card" style="width: 370px; border-radius: 15px;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 110px; text-align: right" class="mr-3">

                                <v-img src="https://d153chakadu90d.cloudfront.net/web/hiw_2.png" class="ml-5 mt-3"
                                    width="120%" alt="ChatGPT ChatPro How it Works"></v-img>
                            </v-col>

                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">2. Customize</p>
                                <p style="color:white;">Select your colors and upload a logo.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="glass-card" style="width: 370px; border-radius: 15px;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 110px; text-align: right" class="mr-3">

                                <v-img src="https://d153chakadu90d.cloudfront.net/web/hiw_3.png" class="ml-5 mt-3"
                                    width="120%" alt="ChatGPT ChatPro How it Works"></v-img>
                            </v-col>

                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">3. Share</p>
                                <p style="color:white;">Share your Assistant link or embed in your site.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <div class="hidden-sm-and-down">
                <video  src="https://d153chakadu90d.cloudfront.net/web/tutorial.mp4" poster="https://d153chakadu90d.cloudfront.net/web/chatpro_tumbnail.svg" style="width: 85%;" class="mt-15" loop autoplay muted>
                    Your browser does not support the video tag.
                </video>
            </div>
        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'MultipurposeView',
    components: {
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;

}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;
    border-radius: 20px;
}
</style>
