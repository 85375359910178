import { render, staticRenderFns } from "./supportAssistant.vue?vue&type=template&id=bd6de642&scoped=true&"
import script from "./supportAssistant.vue?vue&type=script&lang=js&"
export * from "./supportAssistant.vue?vue&type=script&lang=js&"
import style0 from "./supportAssistant.vue?vue&type=style&index=0&id=bd6de642&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd6de642",
  null
  
)

export default component.exports