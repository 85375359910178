<template>
    <v-container style="text-align: center; justify-content: flex-end" class="mt-6"> 
        <center>
            <v-row class="mt-10">
                <v-col >
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-translate</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">Answer to your clients and teammates in many languages.</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="50">mdi-lightning-bolt-circle</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">Create and share your AI Assistant super easy and fast.</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'MultipurposeView',
    components: {
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>
