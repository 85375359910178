<template>
    <v-container style="text-align: center;" class="mt-6">
        <center>
            <h2 class="hidden-sm-and-down title-style mt-7">Create Multipurpose Assistants super fast.</h2>
            <h2 class="hidden-md-and-up title-style-mb mt-3">Create Multipurpose Assistants super fast.</h2>
            <v-row class="mt-13">
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-handshake-outline</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">For Sales</p>
                                <p style="color:white;">Accurate recommendations to your customers. ChatGPT now is the
                                    expert of your Business.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-auto-fix</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">For Support CX</p>
                                <p style="color:white;">Solve any doubts of your clients or teammates.</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-rocket-outline</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">For Anything</p>
                                <p style="color:white;">Truly your imagination is the limit.</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <video src="https://d153chakadu90d.cloudfront.net/web/chatpro_how_it_works_v2.mp4" poster="https://d153chakadu90d.cloudfront.net/web/chatpro_tumbnail.svg" style="width: 100%; border-radius: 15px;" class="mt-15" loop autoplay muted>
                Your browser does not support the video tag.
            </video>
        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'MultipurposeView',
    components: {
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>
