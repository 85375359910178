import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import VueScrollTo from 'vue-scrollto'
import VueSimpleAlert from "vue-simple-alert";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import './styles.scss'


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlu_RXUpfW2MalpBtCjmFwSy_cJNUg-YQ",
  authDomain: "chatpro-startup-385421.firebaseapp.com",
  projectId: "chatpro-startup-385421",
  messagingSenderId: "806543137647",
  appId: "1:806543137647:web:0407576429724709ca269a",
  measurementId: "G-Y0DFXMKESS"
}


// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

Vue.config.productionTip = false
Vue.use(VueSimpleAlert);


new Vue({
  vuetify,
  router,
  render: h => h(App)
  
}).$mount('#app')




