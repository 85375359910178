<template>
    <v-container fluid>
        <center>
            <v-row>
                <v-col v-for="(plan, index) in plans" :key="index" class="mx-auto">
                    <v-card style="border-radius: 15px; height: 320px; width: 270px;">
                        <v-card-title class="ml-2">{{ plan.title }}<v-btn v-if="plan.title == 'Plus'" class="ml-5" depressed
                                rounded outlined x-small>Popular</v-btn></v-card-title>
                        <h1 class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">
                            {{ plan.price }}<span style="font-size: small; font-weight: lighter;">/month</span>
                        </h1>
                        <v-list-item three-line class="mt-4">
                            <v-list-item-content class="ml-2" style="text-align: left;">
                                <v-list-item-title v-for="(feature, fIndex) in plan.features" :key="fIndex">
                                    <v-icon left size="15" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                                    <span style="font-size: small;">{{ feature }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-actions class="pt-10">
                            <v-btn @click="subscribePlan(index)" rounded color="black" block class="white--text"
                                :disabled="freeButtonDisabled && index === 0" :loading="freeButtonDisabled && index === 0">Subscribe</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            plans: [
                {
                    title: 'Free',
                    price: '$0',
                    features: [
                        '1 ChatGPT Assistant',
                        '20 credits/month'
                    ]
                },
                {
                    title: 'Starter',
                    price: '$17',
                    features: [
                        '4 ChatGPT Assistants',
                        '2,000 credits/month'
                    ]
                },
                {
                    title: 'Plus',
                    price: '$47',
                    features: [
                        '10 ChatGPT Assistants',
                        '5,000 credits/month'
                    ]
                },
                {
                    title: 'Premium',
                    price: '$97',
                    features: [
                        '20 ChatGPT Assistants',
                        '10,000 credits/month'
                    ]
                },
                {
                    title: 'Ultimate',
                    price: '$397',
                    features: [
                        '40 ChatGPT Assistants',
                        '40,000 credits/month'
                    ]
                }
            ],
            freeButtonDisabled: false
        };
    },

    methods: {
        subscribePlan(plan) {
            if(plan == 0){
                this.freeButtonDisabled = true;
            }

            // console.log("User select plan " + plan)
            this.$emit('variable-plan', plan);
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #000000;
    font-size: 900;
    font-weight: 550;
    line-height: 65px;
    text-align: left;
}

.intro-text-head-mb {
    color: #000000;
    font-size: 900;
    font-weight: 550;
    line-height: 45px;
    text-align: left;

}

.intro-text-head-desc {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}

.intro-text-head-desc-mb {
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.431);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>

