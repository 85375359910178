import axios from "axios";
// import authFirebase from "../../authFirebase";
// import ENDPOINT_API_USERS from "@/logic/globalEndpoints";
const { ENDPOINT_API_USERS } = require("@/logic/globalEndpoints")


export default {


    //++++++++++++++++ Metodo POST para REGISTRAR UN NUEVO USUARIO A CHATPRO +++++++++++++++++++++++++
    async activate_payment(id_user, id_plan) {

        const new_payment_model = {
            id_user: id_user,
            id_plan: id_plan
        };

        const resp = await axios.put(ENDPOINT_API_USERS + "/api/b2c/payment-user", new_payment_model)
        return resp;
    },




}