<template>
    <v-container style="text-align: center;" class="mt-6">
        <center>
            <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-2 mb-7 hidden-sm-and-down">A powerful
                platform to keep an eye on your AI Assistants team.</h2>
            <h2 style="color: white; font-weight: 600; font-size: 22px;" class="mt-2 mb-7 hidden-md-and-up">A powerful
                platform to keep an eye on your AI Assistants team.</h2>
            <v-img contain src="https://d153chakadu90d.cloudfront.net/web/chatgpt_platform_preview.png"
                height="110%" alt="ChatPro Platform for create personalized ChatGPT Chatbots"></v-img>

            <v-card rounded class="mb-12 mt-15 hidden-sm-and-down" color="black"
                style="width: 800px; height: 170px; border-radius: 20px !important;">
                <v-parallax src="https://d153chakadu90d.cloudfront.net/web/parallax_test_2.png" height="170">
                    <v-row>
                        <v-col style="text-align: left;" class="ml-10">
                            <h2 style="color: white; font-weight: 600; font-size: 30px;" class="my-3">Take an advantage for
                                your Business with ChatGPT.</h2>
                        </v-col>
                        <v-col style="padding-top: 70px;" class="ml-10">
                            
                                <v-btn @click="goToRegister()" rounded>
                                    Start now<v-icon right>mdi-chevron-right</v-icon>
                                </v-btn>
                        </v-col>
                    </v-row>
                </v-parallax>
            </v-card>

            <v-card rounded class="mb-12 mt-15 hidden-md-and-up" color="black"
                style="width: 800px; height: 180px; border-radius: 20px !important;">
                <v-parallax src="https://d153chakadu90d.cloudfront.net/web/parallax_test_2.png" height="180">
                    <v-row class="mt-6">
                        <h2 style="color: white; font-weight: 600; font-size: 22px;" class="mx-3">Take an advantage for your
                            Business with ChatGPT.</h2>
                    </v-row>
                    <v-row class="mx-auto">
                        
                        <v-btn @click="goToRegister()" rounded>
                            Start now<v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-parallax>
            </v-card>

        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  
// 

export default {
    name: 'MultipurposeView',
    components: {
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro landing
        },
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>
